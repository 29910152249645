<template>
  <div class="table-common customer-order">
    <div class="head">
        <!-- 可修改返回对象的键名heoptions的键名 具体查看组件 -->
      <Header 
        show-date
        show-search
        select-key="product"
        :options="options"
        @commands="handleScreen"
        btn-content-one="导入" 
        btn-content-two="下载商品模板" 
        btn-one-icon=""
        btn-two-icon=""
        @btnOne="handleExport"
        @btnTwo="handleDown"
        />
    </div>
    <div class="line"></div>
    <div class="info">
      <Sum-number :number="20"></Sum-number>
       <div style="margin-top: 10px;">
         <lida-table
            :pagination="false"
            :cutHeight="30"
            :data="lists"
            border
            ref="table"
          >
            <!-- :height="tableHeight" -->
            <el-table-column type="selection" width="55" />
            <el-table-column type="index" label="序号" width="55" />
            <template v-for="(item, index) in tableHead" :key="index">
              <lida-table-column :label="item.label" v-if="item.label === '注册资金'">
                <template #default="scope">
                  <div>${{scope.row.price}}</div>
                </template>
              </lida-table-column>
              
              <lida-table-column :label="item.label" :prop="item.key" v-else/>
            </template>
            <lida-table-column label="操作" width="50">
              <template #default="scope">
                <Table-menus :row="scope.row"
                            :index="scope.$index"
                            :data="scope.row.menusData"
                            @command="commands"/>
              </template>
            </lida-table-column>
          </lida-table>
          <div class="page">
            <el-pagination
              v-model:currentPage="currentPage"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
       </div>
    </div>
  </div>
</template>
<script>
/**
 * @Date 2021-11-15
 * @Desc 报价明细
 */
import Header from "@/components/publicComponent/lidaHeader.vue";
import SumNumber from "@/components/publicComponent/sumNumber.vue";
import TableMenus from "@/components/publicComponent/tableMenus.vue";
export default {
  components: {
    Header,
    SumNumber,
    TableMenus
  },
  data(){
    return {
      options: [ // 下拉框筛选
        {label: '客户名称', value: '1'},
        {label: '手机号', value: '2'},
        {label: '关联企业名称', value: '3'},
        {label: '类型', value: '4'},
        {label: '状态', value: '5'},
        {label: '等级', value: '6'},
      ],
      tableHead: [ // 表格头部
        { label: '导入时间', key: 'createTime' },
        { label: '产品信息', key: 'productInfo' },
        { label: '订货号/型号', key: 'model' },
        { label: '发货地区', key: 'site' },
        { label: '生产日期', key: 'date' },
        { label: '渠道', key: 'channel' },
        { label: '数量', key: 'number' },
        { label: '面价', key: 'price' },
      ],
      lists: [],// 列表数据
      receiptsStates: ['待报价', '已报价'], // 单据状态
      currentPage: 1, //页数
    }
  },
  methods: {
    getList() {
      let arr = [
       { createTime: '2021-11-16', productInfo: 'vigi ic65漏电模块 电气附件|漏电模块|1P+N', model: '订货号：xxxx型号：xxxx', site: '成都', number: '0.8', price: '100'}
      ]
      arr.forEach(item => {
        let menus =  [
              {name: '查看'},
              {name: '删除'},
            ]
        item.menusData = menus
      })
      this.lists = [...arr]
    },
    handleExport(v){
      // 头部筛选
    },
    handleDown(v){
      // 头部筛选
    },
    handleSizeChange() {
      // 每页条数修改-
    },
    handleCurrentChange() {
      // 页数改变
    },
    commands(v) {
      // 表格操作出发
      /**
       * v.dropdownName 菜单名字
       * v.index 菜单索引
       * v.row 菜单列表
       */
    }
  },
  created() {
    this.getList()
  }
};
</script>
<style lang="less" scoped>
</style>

